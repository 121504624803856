import { useState, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import useOutsideClick from "../../hooks/useOutsideClick";

import { languageOptions } from "../../data/languages";

import styles from "./LanguagePicker.module.scss";
import { FaChevronRight } from "react-icons/fa6";

export default function LanguagePicker({ onChangeLang, language, className }) {
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef();
  const toggleRef = useRef();

  const changeLanguageHandler = (lang) => {
    onChangeLang(lang);
    setShowModal(false);
  };

  // Use the custom hook to handle outside click of the dropdown
  useOutsideClick(dropdownRef, (event) => {
    if (
      showModal &&
      toggleRef.current &&
      !toggleRef.current.contains(event.target)
    ) {
      setShowModal(false);
    }
  });

  const selectedLanguage = languageOptions.find((lang) => {
    return lang.value === language;
  });

  return (
    <div className={`${styles["language-picker"]} ${className}`}>
      <button
        className={styles.btn}
        onClick={() => {
          setShowModal((prev) => !prev);
        }}
        ref={toggleRef}
      >
        <ReactCountryFlag countryCode={selectedLanguage.flag} svg />
        <span>{selectedLanguage.value}</span>
        <FaChevronRight
          className={`${styles.arrow} ${showModal ? styles.rotate : ""}`}
        />
      </button>

      <div
        className={`${styles.modal} ${showModal ? styles.active : ""}`}
        ref={dropdownRef}
      >
        {languageOptions.map((lang) => {
          return (
            <button
              className={styles["lang-btn"]}
              onClick={() => changeLanguageHandler(lang.value)}
              key={`language-${lang.value}`}
            >
              <ReactCountryFlag countryCode={lang.flag} svg />
              <span>{lang.text}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
