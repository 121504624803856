import styles from "./CTAEdit.module.scss";

import { useRef, useState } from "react";
import EditableCard from "../../../UI/EditableCard";

export default function CTAEdit({ title, category, content, lang, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);

  const { subtitle, description, cta } = content;

  const subtitleRef = useRef();
  const descriptionRef = useRef();
  const ctaRef = useRef();

  const submitHandler = () => {
    const enteredSubtitle = subtitleRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredCta = ctaRef.current.value;

    onUpdate((prev) => {
      const langContent = prev.lang[lang];
      const updatedCategory = {
        ...langContent.cta[category],
        subtitle: enteredSubtitle,
        description: enteredDescription,
        cta: enteredCta,
      };
      return {
        ...prev,
        lang: {
          ...prev.lang,
          [lang]: {
            ...langContent,
            cta: {
              ...langContent.cta,
              [category]: updatedCategory,
            },
          },
        },
      };
    });

    setIsEditing(false);
  };

  return (
    <EditableCard
      title={title}
      isEditing={isEditing}
      onEdit={() => setIsEditing((prev) => !prev)}
      onSubmit={submitHandler}
      className={styles["editable-card"]}
    >
      {isEditing ? (
        <>
          <input
            id={`cta-${category}-subtitle`}
            type="text"
            defaultValue={subtitle}
            ref={subtitleRef}
          />{" "}
          <input
            id={`cta-${category}-description`}
            type="text"
            defaultValue={description}
            ref={descriptionRef}
          />
          <input
            id={`cta-${category}-cta`}
            type="text"
            defaultValue={cta}
            ref={ctaRef}
          />
        </>
      ) : (
        <>
          <p className={styles["cta-subtitle"]}>{subtitle}</p>
          <p className={styles["cta-description"]}>{description}</p>
          <p className={styles["cta-cta"]}>{cta}</p>
        </>
      )}
    </EditableCard>
  );
}
