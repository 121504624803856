import IconContentsEdit from "./IconContentsEdit";
import styles from "./IconContentsItem.module.scss";

export default function IconContentsItem({
  title,
  category,
  icon,
  content,
  lang,
  onUpdate,
}) {
  return (
    <div className={styles["icon-items"]}>
      <img src={icon} alt="" className={styles.icon} />
      {<p className={styles.title}>{title}</p>}
      <IconContentsEdit
        category={category}
        lang={lang}
        content={content}
        onUpdate={onUpdate}
      />
    </div>
  );
}
