import SectionWrapper from "../../../UI/Layout/SectionWrapper";
import IconContentsItem from "./IconContentsItem";

import styles from "./IconContents.module.scss";

function IconContentsSection({ iconContents, lang, onUpdate }) {
  return (
    <SectionWrapper
      title="Icon Contents"
      description="You can change the content of the icons here"
    >
      <div className={styles["icon-items-container"]}>
        <IconContentsItem
          title="Link"
          category="link"
          icon="/assets/icons/link.svg"
          content={iconContents.link}
          lang={lang}
          onUpdate={onUpdate}
        />
        <IconContentsItem
          title="Share"
          category="share"
          icon="/assets/icons/share.svg"
          content={iconContents.share}
          lang={lang}
          onUpdate={onUpdate}
        />
        <IconContentsItem
          title="Connect"
          category="connect"
          icon="/assets/icons/connect.svg"
          content={iconContents.connect}
          lang={lang}
          onUpdate={onUpdate}
        />
      </div>
    </SectionWrapper>
  );
}

export default IconContentsSection;
