import { useRef, useState } from "react";

import EditableCard from "../../UI/EditableCard";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./HeroMessage.module.scss";

export default function HeroMessageSection({ heroMessage, lang, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const { title, subtitle } = heroMessage;

  const titleRef = useRef();
  const subtitleRef = useRef();

  const submitHandler = () => {
    const enteredTitle = titleRef.current.value;
    const enteredSubtitle = subtitleRef.current.value;

    onUpdate((prev) => ({
      ...prev,
      lang: {
        ...prev.lang,
        [lang]: {
          ...prev.lang[lang],
          heroMessage: {
            ...prev.lang[lang].heroMessage,
            title: enteredTitle,
            subtitle: enteredSubtitle,
          },
        },
      },
    }));

    setIsEditing(false);
  };

  return (
    <SectionWrapper
      title="Hero Message"
      description="You can change the content of the message on the hero image here."
    >
      <EditableCard
        onEdit={() => setIsEditing((prev) => !prev)}
        onSubmit={submitHandler}
        isEditing={isEditing}
        className={styles["editable-card"]}
      >
        {isEditing ? (
          <>
            <input
              id={`hero-message-title`}
              type="text"
              defaultValue={title}
              ref={titleRef}
            />{" "}
            <input
              id={`hero-message-subtitle`}
              type="text"
              defaultValue={subtitle}
              ref={subtitleRef}
            />
          </>
        ) : (
          <>
            <p className={styles["hero-message-title"]}>{title}</p>
            <p className={styles["hero-message-subtitle"]}>{subtitle}</p>
          </>
        )}
      </EditableCard>
    </SectionWrapper>
  );
}
