import { useRef, useState } from "react";
import EditableCard from "../../../UI/EditableCard";

import styles from "./IconContentsEdit.module.scss";

export default function IconContentsEdit({
  category,
  content,
  lang,
  onUpdate,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const { subtitle, description } = content;

  const subtitleRef = useRef();
  const descriptionRef = useRef();

  const submitHandler = () => {
    const enteredSubtitle = subtitleRef.current.value;
    const enteredDescription = descriptionRef.current.value;

    onUpdate((prev) => {
      const langContent = prev.lang[lang];
      const updatedCategory = {
        ...langContent.icons[category],
        subtitle: enteredSubtitle,
        description: enteredDescription,
      };

      return {
        ...prev,
        lang: {
          ...prev.lang,
          [lang]: {
            ...prev.lang[lang],
            icons: {
              ...langContent.icons,
              [category]: updatedCategory,
            },
          },
        },
      };
    });

    setIsEditing(false);
  };

  return (
    <EditableCard
      isEditing={isEditing}
      onEdit={() => setIsEditing((prev) => !prev)}
      onSubmit={submitHandler}
      className={styles["icon-editable-card"]}
    >
      {isEditing ? (
        <>
          <input
            id={`icons-${category}-subtitle"`}
            type="text"
            defaultValue={subtitle}
            ref={subtitleRef}
          />{" "}
          <textarea
            id={`icons-${category}-description"`}
            type="text"
            defaultValue={description}
            ref={descriptionRef}
          ></textarea>
        </>
      ) : (
        <>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.description}>{description}</p>
        </>
      )}
    </EditableCard>
  );
}
